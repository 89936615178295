/**
 * ./composables/activityTypes.ts
 */
import type {IActivityCategory} from "~/types/ActivityCategory";

const state = reactive({
  activityTypes: [],
  activityCategories: [],
});

export const useActivityAttributes = () => {
    const getActivityTypes = computed<IActivityType[]>(() => {
        if(state.activityTypes.length <= 0) {
            loadActivityTypes();
        }

        return state.activityTypes;
    });

    const loadActivityTypes = async () => {
        if(state.activityTypes && state.activityTypes.length > 0) {
            return state.activityTypes;
        }

        const { data, error } = await useApiFetch('/api/activities/types');

        state.activityTypes = data.value?.types;

        return state.activityTypes;
    }

    const reorderActivityTypes = async (types: IActivityType[]): IActivityType[] => {
        const { data } = await useApiFetch('/api/activities/types/reorder', {
            method: 'PUT',
            body: { 'types': types },
        });

        state.activityTypes = data.value?.types;

        return state.activityTypes;
    }

    const reorderCategoriesTypes = async (categories: IActivityCategory[], selectedActivityId: int): Promise<IActivityCategory[]> => {

        const {data } = await useApiFetch('/api/activities/categories/reorder', {
            method: 'PUT',
            body: {
                'categories': categories,
                'typeId': selectedActivityId
            }
        });

        state.activityCategories = data.value?.categories;
        return state.activityCategories;

    }


    const reorderCategories = async (categories: IActivityCategory[], activeType: number): IActivityCategory[] => {
        const {data} = await useApiFetch('/api/activities/')
    }

    const updateActivityType = async (type: IActivityType, categories: IActivityCategory[]) => {
        const { data } = await useApiFetch('/api/activities/types/'+type.id, {
            method: 'PUT',
            body: {
                name: type.name,
                order: type.order,
                category_ids: categories.map((category) => category.id),
            },
        });

        return data.value?.type;

    }

    const loadActivityCategories = async (activityTypeId?:number, active?:boolean) => {
        let url = '/api/activities/categories?';

        if (activityTypeId) {
            url = url+'&activity_type_id='+activityTypeId;
        }

        if (active) {
            url = url+'&active='+active;
        }

        const { data, error } = await useApiFetch(url);

        state.activityCategories = Array.from(data.value.categories);
        state.activityCategories.sort((a, b) => {
            if(a.name < b.name) {
                return -1;
            }

            if(a.name > b.name) {
                return 1;
            }

            return 0;
        });

        return state.activityCategories;
    }

    const createActivityCategory = async (name: string) => {
        const { data } = await useApiFetch('/api/activities/categories', {
            method: 'POST',
            body: {
                'name': name,
            }
        });

        return data.value?.category;
    }

    const updateActivityCategory = async (category: IActivityCategory) => {
        const { data } = await useApiFetch('/api/activities/categories/'+category.id, {
            method: 'PUT',
            body: {
                'name': category.name,
                'active': category.active
            }
        });

        return data.value?.category;
    }

    const loadRelationshipRatings = async () => {
        let ratings: number[] = [1, 25, 50, 75, 100];
        let returns: object[] = [];

        ratings.forEach((rating: number) => {
            returns.push({
                score: rating,
                emoji: getEmojiForRating(rating),
            });
        });

        return returns;

    }

    const getEmojiForBand = (band: number): string => {
        switch (band) {
            case 0:
            case 1:
                return "🤬";
            case 2:
                return "🙁";
            case 3:
                return "🙂";
            case 4:
                return "😀";
            case 5:
            case 6:
                return "🥰";
        };

        return '-';
    };

    const getRatingBandFromRating = (rating: number): number => {
        return (Math.floor(rating / 20) + 1);
    }

    const getEmojiForRating = (rating: number): string => {
        const band = getRatingBandFromRating(rating);

        return getEmojiForBand(band);
    }

    return {
        getActivityTypes,
        loadActivityTypes,
        reorderActivityTypes,
        reorderCategoriesTypes,
        updateActivityType,
        loadActivityCategories,
        createActivityCategory,
        updateActivityCategory,
        loadRelationshipRatings,
        getEmojiForBand,
        getEmojiForRating,
        getRatingBandFromRating,
    }
}
